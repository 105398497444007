/* Calendar Module Style */
.app-calendar,
.rbc-calendar {
  font-family: 'Be Vietnam', serif;
  width: 100%;
}

.app-calendar .rbc-calendar {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 100%;
  min-height: calc(100vh - 360px);
}

.app-cul-calendar .rbc-calendar {
  min-height: calc(100vh - 400px);
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  -o-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
  border: solid 1px #e5e9ec;
}

.rbc-month-row {
  min-height: 100px;
}

.rbc-event {
  min-height: 50px;
}

.rbc-event:focus {
  outline: none;
}

.hs-calendar .react-daypicker-root {
  border: solid 1px #e5e9ec;
  border-radius: 30px;
  padding: 30px;
  width: 100%;
  height: 100%;
}

.hs-calendar table {
  width: 100%;
  height: calc(100% - 30px);
}

.hs-calendar table td {
  position: relative;
}

.hs-calendar table th {
  text-transform: uppercase;
  font-weight: var(--light);
  font-size: 15px;
}

.hs-calendar .header .month-year {
  font-weight: var(--extra-bold);
  padding-top: 3px;
}

.hs-calendar-color .react-daypicker-root {
  border: 0 none;
  font-size: 20px;
}

.hs-calendar-color .header {
  padding: 30px;
  margin: -30px -30px 0;
}

.hs-calendar-color .header .month-year {
  font-size: 22px;
}

.hs-calendar-color table {
  font-weight: var(--extra-bold);
  height: calc(100% - 60px);
}

.hs-calendar-color table thead th {
  font-weight: var(--extra-bold);
  font-size: 20px;
}

@media screen and (max-width: 1919px) {
  .hs-calendar .react-daypicker-root {
    padding: 20px;
  }

  .hs-calendar .react-daypicker-root th,
  .hs-calendar .react-daypicker-root td {
    width: 30px;
  }

  .hs-calendar table th,
  .hs-calendar table td {
    font-size: 12px;
  }

  .hs-calendar-color .react-daypicker-root {
    font-size: 16px;
  }

  .hs-calendar-color .header {
    padding: 20px;
    margin: -20px -20px 0;
  }

  .hs-calendar-color .header .month-year {
    font-size: 18px;
  }

  .hs-calendar-color table thead th {
    font-size: 15px;
  }

  .hs-calendar-color table {
    height: calc(100% - 70px);
  }
}

@media screen and (max-width: 1367px) {
  .hs-calendar-color table thead th {
    font-size: 13px;
  }
}

@media screen and (max-width: 959px) {
  .rbc-toolbar {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }

  .rbc-toolbar .rbc-toolbar-label {
    margin: 8px 0;
  }

  .hs-calendar table {
    height: auto;
  }
}

@media screen and (max-width: 599px) {
  .hs-calendar .react-daypicker-root {
    padding: 10px;
  }

  .hs-calendar .react-daypicker-root th,
  .hs-calendar .react-daypicker-root td {
    width: 20px;
  }

  .app-calendar .rbc-calendar {
    min-height: 400px;
  }

  .app-cul-calendar .rbc-calendar {
    min-height: 400px;
  }
}

@media screen and (max-width: 467px) {
  .rbc-toolbar {
    font-size: 12px;
  }

  .rbc-toolbar button {
    padding: 5px 10px;
  }

  .hs-calendar table th,
  .hs-calendar table td {
    font-size: 12px;
  }
}
